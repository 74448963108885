/**
 * Returns an object with all app translations in the current locale.
 */
async function getAppTranslations()
{
  const url = '/translations';

  let translations = [];
  await fetch(url)
    .then((response) => { return response.json(); })
    .then((json) => { translations = json; })
  ;

  return translations;
}

export default {
  getAppTranslations
}
