import passwordUtilities from './form_utilities'
import translationsHelper from './translations'

(async function() {

  // Test if we are in register form context
  const form = document.querySelector('[data-form=register]')
  if(form == null) {
    return
  }

  const translations = await translationsHelper.getAppTranslations();

  // Business sector dropdown list management (values depends on the category chosen value)
  const inputCategory = document.querySelector('select[name="register_account[category]"]');
  const inputBusinessSector = document.querySelector('select[name="business_sector"]');
  const hiddenInputBusinessSector = document.querySelector('input[name="register_account[business_sector]"');
  const businessSectorFieldGroup = document.getElementById('Form-field-group-business-sector');
  const badAccountCategoryMessagePanel = document.getElementById('BadAccountCategoryMessagePanel');

  if (inputCategory !== null && inputBusinessSector !== null) {

    let refreshBusinessSectors = function() {
      const category = inputCategory.value;

      // If category is "other", then hidding the form and displaying a custom message for user to read the FAQ
      if (category === "autre") {
        businessSectorFieldGroup.setAttribute('aria-hidden', true);
        inputBusinessSector.innerHTML = '<option value="">Sélectionnez un domaine d\'activité ci-dessus</option>';
        checkBasicRequiredField(inputBusinessSector);
        setFieldInvalid(inputCategory);
        badAccountCategoryMessagePanel.setAttribute('aria-hidden', false);
        return;
      }

      badAccountCategoryMessagePanel.setAttribute('aria-hidden', true);

      if (category === "") {
        inputBusinessSector.innerHTML = '<option value="">Sélectionnez un domaine d\'activité ci-dessus</option>';
        checkBasicRequiredField(inputBusinessSector);
        businessSectorFieldGroup.setAttribute('aria-hidden', true);
        return;
      }

      // Enabling company fields
      businessSectorFieldGroup.setAttribute('aria-hidden', false);

      if (!accountCategories.hasOwnProperty(category)) { // variable accountCategories is defined in the register_account.html.twig template
        throw "Unsupported category value : " + category;
      }

      inputBusinessSector.innerHTML = "";
      let options = '<option value="">Sélectionnez un métier</option>';
      for (const sectorId in accountCategories[category].business_sectors) {
        let selected = (hiddenInputBusinessSector.value == sectorId)? 'selected' : '';
        options += '<option value="'+sectorId+'" '+selected+'>'+accountCategories[category].business_sectors[sectorId]+'</option>';
      }
      inputBusinessSector.innerHTML = options;

      checkBasicRequiredField(inputBusinessSector);
    }

    inputCategory.addEventListener('change', function (event) {
      checkBasicRequiredField(inputCategory);
      refreshBusinessSectors();
    });
    checkBasicRequiredField(inputCategory);
    refreshBusinessSectors();

    inputBusinessSector.addEventListener('change', function(event) {
      hiddenInputBusinessSector.value = event.target.value;
      checkBasicRequiredField(inputBusinessSector);
    });
    if (hiddenInputBusinessSector.value == '') {
      hiddenInputBusinessSector.value = inputBusinessSector.value;
      checkBasicRequiredField(inputBusinessSector);
    }
  }

  document.querySelectorAll('[data-input=password]').forEach(password => {
    passwordUtilities.checkPassword(password)
    password.addEventListener('keyup', function () {
      if (passwordUtilities.checkPassword(password)) {
        setFieldValid(password);
        // deletion of error message
        if (password.parentElement.querySelector('.Form-errorMessage--single')) {
          password.parentElement.querySelector('.Form-errorMessage--single').remove();
        }
      } else {
        setFieldInvalid(password);
      }
    })
  })

  document.querySelectorAll('[data-input=basic]').forEach(input => {
    checkBasicRequiredField(input)
    input.addEventListener('change', function () {
      checkBasicRequiredField(input)
    })
  });

  document.querySelectorAll('[data-input=mail]').forEach(input => {
    checkEmail(input)
    input.addEventListener('change', function () {
      checkEmail(input)
    })
  });

  document.querySelectorAll('[data-input=name]').forEach(input => {
    checkName(input)
    input.addEventListener('change', function () {
      checkName(input)
    })
  });

  document.querySelectorAll('[data-input=phone]').forEach(input => {
    checkPhone(input)
    input.addEventListener('change', function () {
      checkPhone(input)
    })
  });

  document.querySelectorAll('[data-input=siret]').forEach(siret => {
    checkSiret(siret)

    siret.addEventListener('keyup', function (event) {
      document.querySelector('[data-input=submit]').setAttribute('disabled', '');

      let siretValue = siret.value
      if (siretValue.length > 14) {
        let newValue = siretValue.trim().replaceAll(' ', '')
        if (newValue !== siretValue)
          siret.value = newValue
      }

      siretValue = siret.value
      if (/^[0-9]{14}$/.test(siretValue)) {
        checkSiret(siret)
      }
      else {
        resetAddressFields()
        setFieldInvalid(siret)
        setFieldErrorMessage(siret, "Veuillez saisir un siret valide")
      }
    });
  })

  let typingTimer;
  document.querySelectorAll('[data-input=companyName]').forEach(companyName => {
    companyName.addEventListener('keyup', function (event) {
      resetAddressFields()
      this.closest('.Form-group').classList.remove('Form-group--valid')
      document.querySelector('[data-input=submit]').setAttribute('disabled', '');
      if (this.value != "") {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
          fetch(Routing.generate('app_dqe_search_company_by_name', {companyName: this.value}))
            .then(data => data.json())
            .then((result) => {
              result = JSON.parse(result)
              b2bAutocomplete(result)
            })
        }, 200);
      }
    })

    // Hide list result B2B
    const listResults = document.querySelector('.SearchBar-results[data-result=b2b]')
    document.addEventListener("click", event => {
      if(event.target !== companyName && event.target !== listResults) {
        listResults.setAttribute('aria-hidden', 'true')
      }
    })
  })

  function checkSiret(siretInput, dqeValidation = true) {
    const siret = siretInput.value.replace(' ', '').trim()
    const formGroup = siretInput.parentNode

    if (siret && dqeValidation) {
      getCompanyFullInfoBySiret(siretInput.value).then(data => {
        setCompanyInfo(data, siret)
        removeError(formGroup)
      })
        .catch(function () {
          setFieldErrorMessage(siretInput, "Veuillez saisir un siret valide")
          document.querySelector('[data-input=submit]').setAttribute('disabled', '');
        })
    }
  }

  function checkEmail(input) {
    if (input.value == '') {
      setFieldNeutral(input);
      return;
    }

    if (validateEmail(input.value)) {
      setFieldValid(input)
    } else {
      setFieldInvalid(input)
    }
  }

  function setFieldValid(input) {
    input.closest('.Form-group').classList.remove('Form-group--error')
    input.closest('.Form-group').classList.add('Form-group--valid')
  }

  function setFieldInvalid(input) {
    input.closest('.Form-group').classList.remove('Form-group--valid')
    input.closest('.Form-group').classList.add('Form-group--error')
  }

  function setFieldNeutral(input) {
    input.closest('.Form-group').classList.remove('Form-group--valid')
    input.closest('.Form-group').classList.remove('Form-group--error')
  }

  // Reveal hidden field for manual input in society step when clicking on button in popover
  const revealInput = document.querySelector('[data-reveal-input]')
  if(revealInput !== null) {
    document.querySelector('[data-reveal-input]').addEventListener('click', event => {
      event.preventDefault()
      revealInputs(['Field-vat-number'])
    })
  }

  function setFieldErrorMessage(input, message) {
    if (input.nextElementSibling !== null && input.nextElementSibling.classList.contains('Form-errorMessage--single')) {
      input.nextElementSibling.remove();
    }

    if (message !== null) {
      const error = document.createElement('p')
      error.classList.add('Form-errorMessage--single')
      error.innerText = message
      input.after(error)
    }
  }

  /**
   * Check first name and last name with a regex.
   * @param input
   */
  function checkName(input)
  {
    if (input.value == '') {
      setFieldNeutral(input)
      setFieldErrorMessage(input, null)
      return;
    }

    const regex = /^[-'a-zA-ZÀ-ÖØ-öø-ÿ]+[ \-'a-zA-ZÀ-ÖØ-öø-ÿ]*$/i
    if (regex.test(input.value)) {
      setFieldValid(input)
      setFieldErrorMessage(input, null)
    } else {
      setFieldInvalid(input)
      setFieldErrorMessage(input, translations['registration.contact.error_bad_last_name'])
    }
  }

  function checkPhone(input) {
    if (input.value == '') {
      setFieldNeutral(input)
      removeError(input.parentNode)
      return
    }

    let requestUri = Routing.generate('app_dqe_validate_phone', {phoneNumber: input.value, phoneType: input.getAttribute('data-phone-type')})
    fetch(requestUri)
      .then(data => data.json())
      .then((result) => {
        const formGroup = input.parentNode

        if (result.validity) {
          setFieldValid(input)
          input.setAttribute('data-valid', 'true');
          removeError(formGroup)
        } else {
          input.closest('div').classList.add('Form-group--error')
          input.closest('div').classList.remove('Form-group--valid')
          input.setAttribute('data-valid', 'false');
          setFieldErrorMessage(input, result.message);
        }
      })
  }

  /**
   * Checks whether or not an input is filled and colors the input in green if valid.
   * @param input
   */
  function checkBasicRequiredField(input) {
    if (input.value == "")
      input.closest('.Form-group').classList.remove('Form-group--valid')
    else
      input.closest('.Form-group').classList.add('Form-group--valid')
  }

  function removeError(formGroup) {
    if(formGroup.querySelectorAll('.Form-errorMessage--single').length > 0) {
      formGroup.querySelectorAll('.Form-errorMessage--single').forEach( error => {
        error.remove()
      })
    }
  }

  function b2bAutocomplete(data) {
    const autocompleteList = document.querySelector('.SearchBar-results[data-result=b2b]')

    autocompleteList.innerHTML = '' // clear list of results

    for(let i = 1; i < 4; i++) {

      if(data['DATA' + i] === undefined) {
        break
      }

      // Creating proper DOM elements
      const listItem = document.createElement('li') // Element inside list of results

      listItem.classList.add('SearchBar-result')
      listItem.innerText = (`${data['DATA'+ i].CompanyName} - ${data['DATA'+ i].ZIP_Code} - ${data['DATA'+ i].Locality}`)

      autocompleteList.appendChild(listItem);

      // On click replace current field value with autocomplete value
      listItem.addEventListener('click', event => {
        document.querySelector('[data-input="companyName"]').value = data['DATA'+ i].CompanyName
        getCompanyFullInfoBySearchRef(data['DATA'+ i].SEARCH_REF).then(data => {
          if (data.CompanyNumber != '') {
            getCompanyFullInfoBySiret(data.CompanyNumber).then(fullData => {
              setCompanyInfo(fullData, data.CompanyNumber)
            })
          }
        })

        document.querySelectorAll('.Form-group[aria-hidden=true]').forEach(input => {
          input.removeAttribute('aria-hidden')
        })
      })
    }

    autocompleteList.setAttribute('aria-hidden', autocompleteList.innerHTML.trim() === "")
  }

  // Set field value
  function setCompanyInfo(data, siret) {
    const companyNameInput = document.querySelector('[data-input=companyName]')
    const zipCodeInput = document.querySelector('[data-input=zipCode]')
    const siretInput = document.querySelector('[data-input=siret]')
    const addressInput = document.querySelector('[data-input=address]')
    const cityInput = document.querySelector('[data-input=city]')

    companyNameInput.value = data.L1_NORMALISEE
    zipCodeInput.value = data.CODPOS
    siretInput.value = siret
    addressInput.value = data.L4_NORMALISEE
    cityInput.value = data.LIBCOM

    setFieldValid(companyNameInput)
    setFieldValid(siretInput)

    // Disabling filled inputs
    addressInput.disabled = addressInput.value.trim() !== ""
    cityInput.disabled = cityInput.value.trim() !== ""
    zipCodeInput.disabled = zipCodeInput.value.trim() !== ""

    revealInputs(['Field-address', 'Field-zipcode', 'Field-city', 'Field-vat-number']);

    document.querySelector('[data-input=submit]').removeAttribute('disabled')
  }

  /**
   * Re-enables address fields and resets validation.
   */
  function resetAddressFields() {
    let address = document.querySelector('[data-input=address]')
    let zip = document.querySelector('[data-input=zipCode]')
    let city = document.querySelector('[data-input=city]')
    address.disabled = false
    zip.disabled = false
    city.disabled = false
    address.value = '';
    zip.value = '';
    city.value = '';
    hideInputs(['Field-address', 'Field-city', 'Field-zipcode'])
  }

  /**
   * Returns company full data by company number (siret)
   *
   * @param companyNumber
   * @returns {Promise<*>}
   */
  function getCompanyFullInfoBySiret(companyNumber) {
    return fetch(Routing.generate('app_dqe_get_company_info_by_siret', {siret: companyNumber.replace(' ', '')}))
      .then(data => data.json())
      .then((response) => JSON.parse(response))
      .then(data => {
        if (data.DQE_status == 'NOT FOUND') {
          throw new Error("Company not found")
        }
        return data
      })
  }

  /**
   * Returns company full data by search reference.
   *
   * @param searchRef
   * @returns {Promise<*>}
   */
  function getCompanyFullInfoBySearchRef(searchRef) {
    return fetch(Routing.generate('app_dqe_get_company_info', {searchRef: searchRef.replace(' ', '')}))
      .then(data => data.json())
      .then((response) => JSON.parse(response))
      .then(data => {
        if (data.DATA1 !== undefined)
          return data.DATA1
        throw new Error("Search reference not found")
      })
  }

  /**
   * Removes the 'aria-hidden' attribute of a set of elements.
   * @param elementIds
   */
  const revealInputs = function(elementIds) {
    if (elementIds !== false) {
      elementIds.forEach(id => {
        document.querySelectorAll('.Form-group[aria-hidden=true][id='+id+']').forEach(input => {
          input.removeAttribute('aria-hidden')
        })
      })
    }
  }

  /**
   * Removes the 'aria-hidden' attribute of a set of elements.
   * @param elementIds
   */
  const hideInputs = function(elementIds) {
    if (elementIds !== false) {
      elementIds.forEach(id => {
        document.querySelectorAll('.Form-group[id='+id+']').forEach(input => {
          input.setAttribute('aria-hidden', 'true')
        })
      })
    }
  }

  function validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return (true)
    }
    return (false)
  }

}());